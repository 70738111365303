/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import { Layout } from '@leshen/gatsby-theme-contentful'
import {
  Billboard,
  SplitContent,
  VariableContent,
  PackageCard,
  LeshenPhoneCTA,
  Image,
  List,
  ListItem,
  LinkButton,
} from '@leshen/gatsby-theme-leshen'
import {
  Stack,
  Columns,
  Column,
  Typography,
  Button,
  Dropdown,
} from '@leshen/ui'
import { Done } from 'emotion-icons/material'
import { graphql, Link as GatsbyLink, navigate } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'
import siteTheme from '../../theme'
import SeoCtaBar from '../components/SeoCtaBar'

const StateTemplate = ({ data }) => {
  const { Page_Path, State, State_Hero_H2 } = data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const brandyPackageList = ['ctl-fiber-940c', 'ctl-phone-fiber-940c']

  const packageList = brandyPackageList
    .map((packageId) =>
      data.allContentfulPackage.edges.find(
        ({ node }) => node.identifier === packageId
      )
    )
    .filter((node) => node)

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: '',
        robots: 'noindex,nofollow',
        title: `CenturyLink Internet in ${State_Name}`,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: 126380,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: current.node.Page_Path,
  }))

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            customLink={GatsbyLink}
            mainContent={
              <>
                <Typography variant="h1">
                  CenturyLink Internet in {State_Name}
                </Typography>
                <Typography variant="h4">{State_Hero_H2}</Typography>
                <LeshenPhoneCTA variant="hero" color="primary">
                  Call
                </LeshenPhoneCTA>
              </>
            }
            image={
              <Image
                data={data.stateHeroImage?.cloudinary[0]?.gatsbyImageData}
                isImageCritical
              />
            }
          />
          <SeoCtaBar />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Explore CenturyLink Internet plans in {State_Name}
                </Typography>
                <Typography variant="h5">
                  Find the best deals in your area.
                </Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl">
              <Columns>
                {packageList.map((currentPackage) => (
                  <PackageCard
                    label={currentPackage.node.label}
                    packageData={{ ...currentPackage.node.brandy }}
                    key={currentPackage.node.contentful_id}
                    content={
                      <>
                        <LinkButton
                          to="/cart"
                          CustomLink={GatsbyLink}
                          color="primary"
                        >
                          Order Online
                        </LinkButton>
                        {/* Disabled lint line due to Gatsby api named
                        variable */}
                        {/* eslint-disable-next-line no-underscore-dangle */}
                        <LeshenPhoneCTA variant="feature" color="primary">
                          Call
                        </LeshenPhoneCTA>
                      </>
                    }
                  />
                ))}
              </Columns>
            </Stack>
          </VariableContent>
          <SplitContent
            style={{ backgroundColor: siteTheme.colors.light }}
            customLink={GatsbyLink}
            image={
              <Image
                data={data.stateSplitImage?.cloudinary[0]?.gatsbyImageData}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Stay connected with CenturyLink Internet in {State_Name}
                </Typography>
                <Typography>
                  {`Get a high-speed internet connection that can keep up with
                  your life. Stream your favorite TV shows and movies, play
                  games online, or work from home. With any CenturyLink Internet
                  plan you'll have:`}
                </Typography>
                <List>
                  <ListItem icon={<Done />}>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        No contract and no data caps
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem icon={<Done />}>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        24/7 technical support
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem icon={<Done />}>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">In-home Wi-Fi</Typography>
                    </Typography>
                  </ListItem>
                </List>

                <Button color="primary" to="/cart">
                  Order Online
                </Button>
              </>
            }
          />{' '}
          <VariableContent
            style={{
              backgroundColor: siteTheme.colors.primary,
              paddingTop: 'calc(0vw + 48px)',
              paddingBottom: 'calc(0vw + 48px)',
            }}
            mainContent={
              <>
                <Typography variant="h2">
                  Find CenturyLink services in these cities
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
            alignMainContent="center"
          />
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h2">
                  Discover other CenturyLink services in {State_Name}
                </Typography>
                <Typography>
                  Learn more about what CenturyLink has to offer. Pick just one
                  service, customize a bundle, and pick a TV service to pair
                  with your plan.
                </Typography>
              </>
            }
          >
            <Stack spacing="xxl" centeredContent>
              <Columns cardStyled>
                <Column>
                  <Typography variant="h4">Bundles</Typography>
                  <Typography>
                    Easily customize a CenturyLink plan that works for you with
                    high-speed internet and a home phone line. You can also add
                    a separate TV plan with all your favorite entertainment.
                  </Typography>
                  <LinkButton
                    to="/bundles"
                    CustomLink={GatsbyLink}
                    color="primary"
                  >
                    Explore Bundles
                  </LinkButton>
                </Column>
                <Column>
                  <Typography variant="h4">Internet</Typography>
                  <Typography>
                    With speeds to handle almost anything you do online,
                    CenturyLink high-speed internet lets you surf with virtually
                    no lag.
                  </Typography>
                  <LinkButton
                    to="/internet"
                    CustomLink={GatsbyLink}
                    color="primary"
                  >
                    Explore Internet
                  </LinkButton>
                </Column>
                <Column>
                  <Typography variant="h4">Entertainment</Typography>
                  <Typography>
                    Enjoy all your favorite channels and on-demand content with
                    a TV plan from one of the available providers.
                  </Typography>
                  <LinkButton to="/tv" CustomLink={GatsbyLink} color="primary">
                    Explore Entertainment
                  </LinkButton>
                </Column>
                <Column>
                  <Typography variant="h4">Home Phone</Typography>
                  <Typography>
                    Call friends and family and talk as long as you want with a
                    crystal-clear connection from your CenturyLink home phone
                    plan.
                  </Typography>
                  <LinkButton
                    to="/phone"
                    CustomLink={GatsbyLink}
                    color="primary"
                  >
                    Explore Phone
                  </LinkButton>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
        </>
      }
    />
  )
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery(
    $stateHeroImage: String
    $stateSplitImage: String
    $pagePath: String
    $stateAbbreviation: String
  ) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerHighspeedCenturylinkAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          Page_Path
          State
          State_Hero_H2
        }
      }
    }
    cities: allDatasetManagerHighspeedCenturylinkAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    stateHeroImage: contentfulMedia(identifier: { eq: $stateHeroImage }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    stateSplitImage: contentfulMedia(identifier: { eq: $stateSplitImage }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    allContentfulPackage {
      edges {
        node {
          __typename # used to determine component to render
          id
          contentful_id # required to make association
          identifier # user created contentful identifier
          brandy {
            name
            details
            bullets {
              text
              disclaimer {
                text
                symbol
              }
            }
            price {
              currency
              sale
              amount
              duration
              prefix
              suffix
              before
              disclaimer {
                text
                symbol
              }
            }
          }
          label
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "a9940654-ae1f-5f31-9da4-4b870f4130f1" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "c37a6ce6-047c-53ee-99ee-569d1479e000" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
